<template>
  <div class="reset__wrapper">
    <vue-header />
    <bread-crumbs :info="info" />

    <div class="reset__container">
      <div class="reset__input-wrapper">
        <p>Введите ваш email</p>
        <input class="reset__input-email" v-model="email" type="email" />
        <p>Введите новый пароль</p>
        <input class="reset__input-pass" v-model="new_password" type="password" />
        <p>Повторите новый пароль</p>
        <input
          class="reset__input-confirm-pass"
          v-model="confirmed_new_password"
          type="password"
        />
        <div class="reset__btn">
          <button @click="changePass()">Сменить пароль</button>
        </div>
        <p class="reset__forgot-message">{{ forgotMessage }}</p>
        <div class="reset__forgotMessageWrapper" v-if="forgotMessageWrapper">
          <router-link class="v-catalog-item-about-link" :to="{ path: '/login' }">
            Авторизироваться
          </router-link>
        </div>
      </div>
    </div>
    <vue-footer />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import vueHeader from '../components/core/vueHeader.vue';
import vueFooter from '../components/core/vueFooter.vue';
import BreadCrumbs from '../components/ui/BreadCrumbs.vue';
import axios from '../plugins/axios';

export default {
  name: 'resetPassword',
  components: { vueHeader, BreadCrumbs, vueFooter },
  data() {
    return {
      forgotMessageWrapper: false,
      email: '',
      forgotMessage: '',
      new_password: '',
      confirmed_new_password: '',
      info: {
        name: 'Восстановление пароля',
        img: require('../assets/img/title/login.png'),
      },
    };
  },
  methods: {
    changePass() {
      axios
        .post('/auth/enter-new-password', {
          email: this.email,
          new_password: this.new_password,
          confirmed_new_password: this.confirmed_new_password,
        })
        .then((res) => {
          this.forgotMessage = res.data.message;
          this.forgotMessageWrapper = true;
          console.warn(res.status);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapState(['passKey']),
  },
  created() {
    axios
      .post('/auth/confirm-hash', {
        key: localStorage.getItem('key'),
        client_hash: this.$route.params.hash,
      })
      .then((response) => {
        console.warn(response.status);
      })
      .catch((err) => {
        if (err) {
          this.$router.push('/login');
        }
      });
  },
};
</script>
<style lang="scss" scoped>
.reset__forgotMessageWrapper {
  margin: 20px;
}
.v-catalog-item-about-link {
  width: 220px;
  margin: 0 auto;
}
.reset__forgot-message {
  font-weight: 600;
  color: green;
}
.reset__input-email {
  font-size: 20px !important;
}
.reset__btn {
  margin: 20px auto;
  width: 250px;
  border: 2px solid black;
  border-radius: 30px;
  padding: 5px 10px;
}
.reset__input-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.reset__input-wrapper p {
  margin-top: 10px;
}
.reset__input-wrapper input {
  text-align: center;
  font-size: 30px;
  color: black;
  margin: 12px auto;
  border: 2px solid black;
  border-radius: 11px;
  height: 16px;
  width: 251px;
  background-color: #8080801c;
}
.reset__input-wrapper {
  margin: 150px auto;
}
.reset__container {
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 800px) {
  .reset__container {
    width: 320px !important;
  }
}
@media (max-width: 1200px) {
  .reset__container {
    width: 800px;
  }
}
@media (min-width: 1201px) {
  .reset__container {
    width: 1200px;
  }
}
</style>
