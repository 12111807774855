<template>
  <div>
    <cab-header />
    <cabinet-section />
    <cab-footer />
  </div>
</template>
<script>
import CabFooter from '../components/CabFooter.vue';
import CabHeader from '../components/CabHeader.vue';
import CabinetSection from '../components/CabinetSection.vue';
export default {
  components: { CabHeader, CabFooter, CabinetSection },
  name: 'cab',
};
</script>
