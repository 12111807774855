<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {},
  methods: {
    ...mapMutations(['AUTH_CHANGE']),
  },
  created() {
    if (localStorage.getItem('token') !== null) {
      this.$store.commit('AUTH_CHANGE');
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/css/main.scss';
@import '@/assets/css/custom.scss';
</style>
