<template>
  <div class="main__password-change">
    <h2 class="main__subheading">Изменить пароль</h2>
    <div class="main__password-info">{{ passwordMessageExternal }}</div>
    <div class="main__password_wrapper">
      <div v-if="currentPasswordWrapper" class="main__password-inputs">
        <input
          type="password"
          class="main__input"
          placeholder="Введите текущий пароль"
          v-model="currentPassword"
        />
        <input
          type="password"
          class="main__input"
          placeholder="Повторите текущий пароль"
          v-model="currentPasswordRepeat"
        />
        <div class="main__password-info">{{ passwordMessage }}</div>
      </div>
      <div v-if="newPasswordWrapper" class="main__password-inputs">
        <input
          type="password"
          class="main__input"
          placeholder="Новый пароль"
          v-model="newPassword"
        />
        <input
          type="password"
          class="main__input"
          placeholder="Ещё раз введите новый пароль"
          v-model="newPasswordRepeat"
        />
      </div>
    </div>
    <div class="password-buttons">
      <div v-if="currentPasswordBtn" class="main__password-save">
        <div class="change-password">
          <button
            type="button"
            class="button main__password-button"
            @click="currentPasswordCheck()"
          >
            Изменить
          </button>
        </div>
      </div>
      <!-- <div @click="forgotPassword" v-if="forgotQuestion" class="forgot-password">
        <button>Забыли пароль?</button>
      </div> -->
    </div>
    <div class="forgotPassInput" v-if="forgotPassInput">
      <input
        type="text"
        v-model="forgotEmail"
        placeholder="Введите вашу почту"
        name=""
        id=""
      />
      <div class="forgotInput-button">
        <button type="submit" @click="forgotPass">Подтвердить</button>
      </div>
    </div>
    <p v-if="forgotMessageWrapper" class="forgotMessage">{{ forgotMessage }}</p>
    <div v-if="enterCodeWrapper">
      <p class="forgotMessage">Введите код восстановления</p>
      <input v-model="enterCode" type="text" />
      <button @click="codeCheck()">Проверить код</button>
    </div>
    <div v-if="savePassWrapper" class="main__password-save">
      <button type="button" class="button main__password-button" @click="savePass()">
        Сохранить
      </button>
      <div class="main__password-info">{{ passwordMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios';

import { emailValidation, passwordValidation } from '../helpers';
export default {
  data() {
    return {
      enterCodeWrapper: false,
      enterCode: '',
      forgotMessageWrapper: false,
      forgotEmail: '',
      forgotQuestion: true,
      forgotPassInput: false,
      passwordMessageExternal: '',
      newPasswordWrapper: false,
      currentPasswordWrapper: true,
      savePassWrapper: false,
      currentPasswordBtn: true,
      passwordMessage: '',
      email: '',
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
      currentPassword: '',
      currentPasswordRepeat: '',
      emailMessage: '',
      forgotMessage: '',
    };
  },
  methods: {
    forgotPassword() {
      this.currentPasswordWrapper = false;
      this.forgotPassInput = true;
      this.currentPasswordBtn = false;
      this.forgotQuestion = false;
    },
    forgotPass() {
      if (this.forgotEmail === '') {
        this.forgotMessageWrapper = true;
        this.forgotMessage = 'Введите свою почту';
        return;
      } else {
        axios
          .post(`/auth/forgot-password`, {
            email: this.forgotEmail,
          })
          .then((res) => {
            if (
              res.data.message === 'Код восстановления пароля отправлен на вашу почту'
            ) {
              this.forgotPassInput = false;
              this.forgotMessageWrapper = true;
              this.forgotMessage = res.data.message;
              // setTimeout(() => {
              //   location.reload();
              // }, 2000);
            }
            this.enterCodeWrapper = true;
          });
      }
    },
    codeCheck() {
      axios
        .post(`/auth/confirm-code`, {
          code: '1234',
          client_code: '1234',
        })
        .then(() => {});
    },
    currentPasswordCheck() {
      if (this.currentPassword == '' || this.currentPasswordRepeat == '') {
        this.passwordMessage = 'Вы не ввели текущий пароль';
        return;
      }
      if (this.currentPassword == this.currentPasswordRepeat) {
        (this.savePassWrapper = true),
          (this.currentPasswordWrapper = false),
          (this.currentPasswordBtn = false),
          (this.newPasswordWrapper = true);
        this.forgotQuestion = false;
        this.passwordMessage = '';
      } else {
        this.passwordMessage = 'Текущие пароли не совпадают';
      }
    },
    savePass() {
      if (this.newPassword !== '') {
        axios
          .post(`/auth/enter-changed-password`, {
            new_password: this.newPassword,
            current_password: this.currentPassword,
          })
          .then((res) => {
            if (this.newPassword !== this.newPasswordRepeat) {
              this.passwordMessage = 'Пароли не совпадают';
            } else {
              if (this.newPassword.length < 6) {
                this.passwordMessage = 'Ваш пароль должен быть 8 символов или более';
              }
              if (res.data.message == 'Текущий пароль неверен!') {
                (this.savePassWrapper = false),
                  (this.currentPasswordWrapper = true),
                  (this.currentPasswordBtn = true),
                  (this.newPasswordWrapper = false);
                this.forgotQuestion = true;
                this.passwordMessage = 'Текущий пароль неверен!';
                return;
              }
              if (passwordValidation(this.newPassword)) {
                this.passwordMessageExternal = 'Ваш пароль был изменен';
                this.savePassWrapper = false;
                this.currentPasswordWrapper = false;
                this.newPasswordWrapper = false;
                this.savePassWrapper = false;
              } else {
                this.passwordMessage =
                  'Пароль должен содержать заглавную букву, спецсимвол и цифру';
              }
            }
          });
      }
    },
    checkEmail() {
      if (emailValidation(this.email)) {
        this.emailMessage = 'Ваша почта изменена';
      } else {
        this.emailMessage = 'Введите верный e-mail';
      }
    },
  },
};
</script>

<style lang="scss">
.main__password-info {
  margin-top: 10px;
  color: red;
}
.password-buttons {
  display: flex;
}
.forgotPassInput input {
  width: 100%;
  max-width: 200px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding: 3px;
  border: 1px solid black;
}
.change-password {
  width: 100%;
  max-width: 200px;
}
.forgot-password {
  text-align: center;
  border: 1px solid #fb4330;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fb4330;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  margin-left: 30px;
  max-width: 200px;
}
.forgotMessage {
  position: static;
  right: 298px;
  bottom: 65px;
  height: 54px;
}
.forgotInput {
  text-align: left;
  padding-left: 30px;
}
.forgotInput-button {
  text-align: center;
  border: 1px solid #fb4330;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fb4330;
  width: 150px;
  cursor: pointer;
  padding: 10px;
}
.main__password-save button {
  text-align: center;
  border: 1px solid #fb4330;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fb4330;
  width: 150px;
  // margin: 10px auto;
  cursor: pointer;
  padding: 10px;
}
.main__password-save {
  display: flex;
  flex-direction: column;
}
.main {
  &__email-change {
    display: flex;
    align-items: center;
  }
  &__email-info {
    display: flex;
    align-items: center;
    margin-left: 48px;
  }
  &__password-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    margin-top: 34px;
  }
  &__password-save {
    display: flex;
    flex-direction: row;
    // align-items: center;
    // margin-bottom: 34px;
  }
  &__password-info {
    display: flex;
    align-items: center;
  }
}
</style>
