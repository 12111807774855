var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main"},[_c('img',{staticClass:"main__decor-image main__decor-image-top",attrs:{"src":require("./../assets/tether.png"),"alt":"decor"}}),_c('div',{staticClass:"main__container container"},[_c('div',{staticClass:"burger-wrapper"},[_c('div',{staticClass:"bar-logo",on:{"click":function($event){_vm.isAppear = true}}},[_c('img',{attrs:{"src":require("../img/bar.png"),"alt":""}})]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"burger",class:{ appear: _vm.isAppear }},[_c('p',{staticClass:"sidebar-close-btn",on:{"click":function($event){_vm.isAppear = false}}},[_vm._v("x")]),_c('ul',{staticClass:"side__navigation"},[_c('li',{class:_vm.finActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = true),
                  (_vm.detailsActive = false),
                  (_vm.hisActive = false),
                  (_vm.payActive = false),
                  (_vm.passActive = false),
                  (_vm.accDetails = false),
                  (_vm.fin = true),
                  (_vm.inc = false),
                  (_vm.his = false),
                  (_vm.pay = false),
                  (_vm.pass = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[0].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[0].title))])],1)]),_c('li',{class:_vm.detailsActive
                  ? 'main__nav-link main__nav-link_active'
                  : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                  (_vm.detailsActive = true),
                  (_vm.hisActive = false),
                  (_vm.payActive = false),
                  (_vm.passActive = false),
                  (_vm.accDetails = true),
                  (_vm.inc = false),
                  (_vm.fin = false),
                  (_vm.his = false),
                  (_vm.pay = false),
                  (_vm.pass = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[1].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[1].title))])],1)]),_c('li',{class:_vm.hisActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                  (_vm.detailsActive = false),
                  (_vm.hisActive = true),
                  (_vm.payActive = false),
                  (_vm.passActive = false),
                  (_vm.his = true),
                  (_vm.inc = false),
                  (_vm.fin = false),
                  (_vm.accDetails = false),
                  (_vm.pay = false),
                  (_vm.pass = false)}}},[_c('p',{class:'main__nav-link'},[_c(_vm.menuItems[2].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[2].title))])],1)]),_c('li',{class:_vm.passActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                  (_vm.detailsActive = false),
                  (_vm.hisActive = false),
                  (_vm.payActive = false),
                  (_vm.passActive = true),
                  (_vm.pass = true),
                  (_vm.fin = false),
                  (_vm.inc = false),
                  (_vm.accDetails = false),
                  (_vm.his = false),
                  (_vm.pay = false)}}},[_c('p',{class:'main__nav-link nav-link-pass'},[_c(_vm.menuItems[4].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[4].title))])],1)])])])]),_c('aside',{staticClass:"sidebar"},[_c('ul',{staticClass:"side__navigation"},[_c('li',{class:_vm.finActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = true),
                (_vm.detailsActive = false),
                (_vm.hisActive = false),
                (_vm.payActive = false),
                (_vm.passActive = false),
                (_vm.accDetails = false),
                (_vm.fin = true),
                (_vm.inc = false),
                (_vm.his = false),
                (_vm.pay = false),
                (_vm.pass = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[0].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[0].title))])],1)]),_c('li',{class:_vm.detailsActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                (_vm.detailsActive = true),
                (_vm.hisActive = false),
                (_vm.payActive = false),
                (_vm.passActive = false),
                (_vm.accDetails = true),
                (_vm.inc = false),
                (_vm.fin = false),
                (_vm.his = false),
                (_vm.pay = false),
                (_vm.pass = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[1].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[1].title))])],1)]),_c('li',{class:_vm.hisActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                (_vm.detailsActive = false),
                (_vm.hisActive = true),
                (_vm.payActive = false),
                (_vm.passActive = false),
                (_vm.his = true),
                (_vm.inc = false),
                (_vm.fin = false),
                (_vm.accDetails = false),
                (_vm.pay = false),
                (_vm.pass = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[2].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[2].title))])],1)]),_c('li',{class:_vm.passActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link',staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.finActive = false),
                (_vm.detailsActive = false),
                (_vm.hisActive = false),
                (_vm.payActive = false),
                (_vm.passActive = true),
                (_vm.pass = true),
                (_vm.fin = false),
                (_vm.inc = false),
                (_vm.accDetails = false),
                (_vm.his = false),
                (_vm.pay = false)}}},[_c('p',{staticClass:"main__nav-link"},[_c(_vm.menuItems[4].svg,{tag:"component"}),_c('span',{staticClass:"main__nav-text"},[_vm._v(_vm._s(_vm.menuItems[4].title))])],1)])])])]),_c('div',{staticClass:"main__content"},[(_vm.fin)?_c('finance',{ref:"fin",on:{"finance":_vm.getfinance}}):_vm._e(),(_vm.fin)?_c('income'):_vm._e(),(_vm.accDetails)?_c('account-details',{ref:"acc",attrs:{"finance":_vm.finance}}):_vm._e(),(_vm.his)?_c('history',{ref:"his"}):_vm._e(),(_vm.pass)?_c('password-change',{ref:"pass"}):_vm._e()],1)]),_c('img',{staticClass:"main__decor-image main__decor-image-bottom",attrs:{"src":require("./../assets/avalanche-avax-logo.png"),"alt":"decor"}})])}
var staticRenderFns = []

export { render, staticRenderFns }