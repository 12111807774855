<template>
  <div class="background">
    <div class="container">
      <div class="nav">
        <div class="nav-left">
          <div class="logo">
            <img src="../img/logo.png" alt="" />
          </div>
          <div v-if="selectedLang" class="lang">
            <p>
              {{ selectedLang.name }}
            </p>
            <p>
              <img :src="selectedLang.img" alt="" />
            </p>
          </div>
          <div @click="langPopup = !langPopup" class="langArrow">
            <img src="../img/langArrow.png" alt="" />
          </div>
          <div v-if="langPopup" class="langPopup">
            <div v-for="lang in langs" :key="lang.index">
              <div @click="changeLang(lang.code, lang)" class="lang-item">
                <p>
                  {{ lang.name }}
                </p>
                <p>
                  <img :src="lang.img" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-right">
          <a href="#">Главная</a>
          <a href="#about">О нас</a>
          <router-link :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }">
            <a>Каталог</a>
          </router-link>
          <a href="#tech">Технологий</a>
          <router-link class="link-a" to="/cab"> Личный Кабинет </router-link>
          <router-link
            :to="{ name: 'cart', params: { cart_data: CART } }"
            class="v-catalog-link-cart"
          >
            <div class="cart-wrapper">
              <img class="cart-img" src="../img/cart.png" alt="" />
            </div>
            <p class="cart-count">{{ CART.length }}</p>
          </router-link>
        </div>
        <div @click="isAppear = true" class="main-bar-logo">
          <img src="../img/bar.png" alt="" />
        </div>
        <div class="body">
          <div v-bind:class="{ appear: isAppear }" class="burger">
            <p class="close-btn" @click="isAppear = false">x</p>
            <ul>
              <li class="basket-burger">
                <router-link
                  :to="{
                    name: 'cart',
                    params: { cart_data: CART },
                  }"
                  class="v-catalog-link-cart"
                >
                  <div class="cart-wrapper">
                    <img class="cart-img" src="../img/cart.png" alt="" />
                    <div class="cart-count">
                      <p class="cart-count-inner">
                        {{ CART.length }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="events">
                <a href="#">Главная</a>
              </li>
              <li class="news"><a href="#about">О нас</a></li>
              <li class="policy">
                <router-link
                  :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }"
                >
                  <a>Каталог</a>
                </router-link>
              </li>
              <li class="policy">
                <a href="#tech">Технологий</a>
              </li>
              <li class="rules">
                <router-link to="/cab">
                  <a href="#">Личный Кабинет</a>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="header-main">
        <h1>Главная</h1>
      </div>
      <div class="routing">
        <a href="#">Главная</a>
        <span class="slash">/</span>
        <router-link :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }">
          <a>Каталог</a>
        </router-link>
      </div>
      <div class="wrapper">
        <h4>ALL POWER IN NUMBERS</h4>
        <div class="header-sign">SYMBIOTE</div>
        <span>The first environmentally friendly cloud mining in Switzerland</span>
        <div class="ready-btn">
          <button>READY TO MINE</button>
        </div>
        <div class="sign">
          <div class="sign-left">
            <p>Symbiote corp</p>
            <br />
            <p class="sign-year">2021</p>
          </div>
          <div class="sign-right">
            <p>
              We are surrounded by numerous hydroelectric power plants, which gives us the
              right to call ourselves Eco-Miners
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import VCatalogItem from '@/components/v-catalog-item';
import vcatalog from './v-catalog.vue';
import headerarrow from './svg/header-arrow.vue';
import auth from '../auth';

export default {
  name: 'mainHeader',
  components: { VCatalogItem, vcatalog, headerarrow, auth },
  data() {
    return {
      isAppear: false,
      langPopup: false,
      routeText: null,
    };
  },
  methods: {
    ...mapMutations(['SELECTED_LANG_MT']),
    ...mapActions(['CART_API']),
    changeLang(code, lang) {
      localStorage.setItem('lang', JSON.stringify(code));
      localStorage.setItem('langEl', JSON.stringify(lang));
      location.reload();
    },
  },
  computed: {
    ...mapGetters(['CART']),
    ...mapState(['selectedLang', 'langs']),
  },
  mounted() {
    // alert(auth.isLogged)
    this.SELECTED_LANG_MT();
    if (!localStorage.getItem('lang') || !localStorage.getItem('langEl')) {
      localStorage.setItem('lang', JSON.stringify(this.langs[0].code));
    }
    this.CART_API().then(() => {});
  },
};
</script>
<style scoped>
.main-bar-logo img {
  width: 50px;
}
.lang {
  align-items: center;
  width: 96.5px;
  z-index: 2;
  display: flex;
  margin-right: 10px;
  justify-content: space-between;
}
.langPopup {
  position: absolute;
  font-family: sans-serif;
  top: 60px;
  left: 184px;
  margin-left: 21px;
  display: block;
  transition: 0.5s;
  opacity: 1;
}
.lang-item img {
  max-width: 27px;
  color: white !;
}
.lang-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
.lang img {
  max-width: 30px;
}

.langArrow {
  position: relative;
  left: 7px;
}
.header-arrow {
}
@media (max-width: 700px) {
  .header-sign {
    font-size: 60px !important;
  }
}
* {
  font-family: 'Montserrat', sans-serif;
}
span {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 50px;
}
.header-sign {
  font-size: 100px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
}
.ready-btn {
  box-shadow: 0px 2px 4px rgb(32 5 97 / 10%), 0px 1px 2px rgb(32 5 97 / 8%);
  border-radius: 4px;
  padding: 22px 40px;
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  width: 222px;
  margin: 20px 133px;
}
.sign {
  height: 80px;
  display: flex;
}
.sign-year {
  color: #f2b816;
  font-size: 14px;
  margin-top: -30px;
}
.sign-right {
  margin-left: 20px;
  width: 400px;
}
.sign-left {
  border-right: 1px solid white;
  padding-right: 20px;
}
.body h4 {
  font-family: sans-serif;
  letter-spacing: 12.7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 50px;
}
.basket-burger {
  width: 10px;
  margin: 0 auto;
  position: relative;
  top: 17px;
  right: 6px;
}
.cart-wrapper {
  margin-top: 34px;
}
.cart-count {
  position: relative;
  left: -74px;
  bottom: 62px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
}
.container {
  padding: 5px;
}
.close-btn {
  cursor: pointer;
  position: relative;
  left: 208px;
  border: 1px solid white;
  padding-bottom: -8px;
  width: 41px;
  z-index: 10;
  font-size: 39px;
  font-weight: 500;
  font-family: 'Montserrat';
}
.burger {
  overflow: auto;
  color: white;
  z-index: 9;
  font-family: sans-serif;
  text-align: center;
  transition: 0.5s;
  position: fixed;
  right: -550px;
  padding: 20px 20px 20px 20px;
  top: 0px;
  width: 315px !important;
  background: rgba(23, 23, 23, 0.709);
  height: 100vh;
}
ul li a {
  text-decoration: none;
}

.main-bar-logo {
  margin: auto;
}
.burger ul li {
  font-weight: 700;
  padding-top: 35px;
  width: 273px;
  padding-left: 8px;
  font-size: 27px;
  text-align: left;
  list-style: none;
  color: #1b2449;
  font-family: sans-serif;
  line-height: 31.8px;
}
a {
  color: white !important;
  text-decoration: none;
}
@media (min-width: 1301px) {
  .cart-count {
    left: 15px;
    top: -37px;
  }
  .main-bar-logo {
    display: none;
  }
}
@media (max-width: 1300px) {
  .burger {
    display: block;
  }
  img.cart-img {
    width: 46px;
    position: relative;
    right: 105px;
  }

  .cart-count {
    width: 35px !important;
    height: 30px !important;
  }
  .cart-count-inner {
    position: relative;
    padding-top: 4px;
    color: white;
    bottom: 4.5px;
  }
  .cart-wrapper {
    margin-top: 34px !important;
    margin: 0 auto;
    width: 50px;
  }
}
.bar-logo {
  margin: auto 20px;
  margin-right: 25px;
}
.body {
  position: absolute;
}
.appear {
  transition: 0.5s;
  position: fixed;
  right: 0px;
}
/* /////////////////////////////////////////////////////////////////////// */
@media (min-width: 1301px) {
  .langPopup {
    left: 232px !important;
  }
  .container {
    margin: 0 auto;
    max-width: 100%;
    width: 1200px;
  }
  .header-main {
    margin-top: 25px;
  }
}
@media (max-width: 1300px) {
  .container {
    margin: 0 auto;
    max-width: 100%;
    width: 800px;
  }
  .header-main {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1050px) {
  .v-catalog-info .background {
    height: auto !important;
  }
  .header-main {
    margin-bottom: 0px !important;
  }
  .routing {
    padding-bottom: 10px;
    /* margin-top: 41px; */
    /* padding-left: 20px; */
  }
  .header {
    float: left;
  }
  .nav {
    padding-top: 25px !important;
  }
}
@media (max-width: 820px) {
  .container {
    width: 700px;
  }
}
@media (max-width: 1300px) {
  .background {
    /* background-size: auto !important; */
  }
  .nav {
    padding-top: 0 !important;
  }
  .container {
    width: 455px;
  }
  .header-main {
    font-size: 13px;
  }
  .nav-left {
    margin-right: 10px;
  }
  .logo {
    margin-right: 10px !important;
  }
  .nav-right {
    display: none !important;
  }
  .header-main {
    margin-top: 10px !important;
  }
  .routing {
    margin-top: 0;
    padding-top: 5px;
  }
  .nav {
    padding-top: 5px !important;
  }
}
.header-main {
  margin-bottom: 28px;
  text-align: left;
}
.routing {
  justify-content: left;
  display: flex;
}
.routing *:nth-child(n) {
  margin-right: 15px;
}
.routing *:first-child {
  color: #ffd800;
}
@media (min-width: 1000px) {
  /* .nav a {
    margin-left: 30px;
  } */
}
.nav-right *:hover {
  transition: 0.5s;
  color: #f2b816;
}
a {
  color: white;
  text-decoration: none;
}
.nav {
  padding-top: 57px;
  display: flex;
  justify-content: space-between;
}
.nav-left,
.nav-right {
  position: relative;
  align-items: center;
  display: flex;
}
.logo {
  margin-right: 59px;
}
.background {
  background-image: url('../img/main-bg.jpg');
  min-height: 100vh;
  background-size: cover;

  padding-bottom: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ffffff;
  position: relative;
}
</style>
