<template>
  <div class="Footer">
    <p class="Footer--come">Присоединяйся</p>
    <p class="Footer--together">Будь вместе с командой SYMBIOT</p>
    <p class="Footer--leave">
      Оставь нам свой имейл чтобы получать различный, но нужный спам
    </p>
    <div class="Footer--input">
      <div class="input__part">
        <p>Email</p>
        <v-text-field placeholder="your@email.com" hide-details=""></v-text-field>
      </div>
      <button class="orange-btn" v-ripple>Отправить</button>
    </div>
    <div class="Footer--social">
      <img src="../../assets/img/footer/twitter.svg" alt="" />
      <img src="../../assets/img/footer/facebook.svg" alt="" />
      <img src="../../assets/img/footer/youtube.svg" alt="" />
    </div>
    <p class="Footer--all">SYMBIOT 2021 Все права защищены</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.Footer--together{
  padding: 10px;
}
.Footer--input {
  width: 100%;
  max-width: 600px;
  padding: 10px;
}
.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/img/footer/bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &--come {
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin: 129px 0 25px !important;
  }
  &--together {
    max-width: 840px;
    font-weight: 900;
    font-size: 60px;
    line-height: 75px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 47px !important;
  }
  &--leave {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-bottom: 65px !important;
  }
  &--input {
    display: flex;
    flex-direction: row;
    margin-bottom: 74px !important;
    .input__part {
      width: calc(612.2950439453125px - 190.49179077148438px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      background: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 4px 0px 0px 4px;
      padding-left: 20px;

      p {
        font-weight: 700;
        font-size: 10px;
        line-height: 10px;
        color: #100c23;
        margin-bottom: 5px !important;
      }
      div {
        height: 12px;
      }
    }
    .orange-btn {
      height: 53px;
      width: 190.49179077148438px;
      border-radius: 0px 4px 4px 0px;
    }
  }
  &--social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 44px !important;
    img {
      margin-right: 30px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  &--all {
    font-weight: 700;
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 114px !important;
  }
}
</style>
